<template>
    <div>

        <!-- <h2>Job</h2>
         <b-button variant="outline-primary" v-b-modal.AddJobTitle>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add Job Title
        </b-button> -->
         <v-btn   color="primary" class="ma-2 white--text"  @click="showAddDialog()">
            Add Job Title <v-icon right dark> mdi-plus</v-icon>
        </v-btn>
        <v-container color="primary">
            <v-card
                icon="clipboard-text"
                title="Job Title"
                class="px-5 py-10">

                <v-card-title>
                    <v-row>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-progress-linear
                            :active="loading"
                            color="green"
                            :indeterminate="loading"
                            absolute
                            bottom
                            height="15">
                            Loading...
                        </v-progress-linear>`
                    </v-row>
                </v-card-title>


                    <v-data-table
                    dense
                    :headers="fields"
                    :items="items"
                    :search="search"
                    class="elevation-1">
                        <template  v-slot:[`header.id`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.jobFamily`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                        <template  v-slot:[`header.englishDescription`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                        <template  v-slot:[`header.arabicDescription`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                        <template  v-slot:[`header.summary`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.requirements`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.responsibilities`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                        <template  v-slot:[`header.Action`]="{ header }">
                            <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template v-slot:top>
                                  <v-dialog
                                    v-model="Editdialog"
                                    max-width="800px">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">Edit Job Title</span>
                                            </v-card-title>
                                            <v-card-text>
                                                 <v-form ref="form" v-model="valid" lazy-validation>
                                                      <v-container>
                                                           <v-row>
                                                                <v-col cols="12" sm="6" md="6">
                                                                    <v-select
                                                                        v-model="editedItem.jobFamilyId"
                                                                        :items="options"
                                                                        :rules="[rules.required]"
                                                                        label="Job Family"
                                                                        required>
                                                                    </v-select>
                                                                </v-col>
                                                           </v-row>
                                                           <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.englishDescription"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="English title">
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.arabicDescription"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="Arabic title">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-textarea
                                                                label="Job Summary"
                                                                :rules="[rules.required]"
                                                                required
                                                                v-model="editedItem.summary"
                                                                ></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-textarea
                                                                label="Job Responsibilities"
                                                                :rules="[rules.required]"
                                                                required
                                                                v-model="editedItem.responsibilities"
                                                                ></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-textarea
                                                                label="Job Requirements"
                                                                :rules="[rules.required]"
                                                                required
                                                                v-model="editedItem.requirements"
                                                                ></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                      </v-container>
                                                 </v-form>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                 <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                                                 <v-btn color="blue darken-1" :disabled="!valid" text @click="validate" >Save</v-btn>
                                             </v-card-actions>
                                        </v-card>
                                  </v-dialog>
                         </template>
                        <template v-slot:[`item.summary`]="{ item }">
                            {{item.summary==null?'':item.summary.substr(0,15)}}
                            <v-tooltip top v-model="summarytt" >
                                <template v-slot:activator="{on,attr}">
                                    <v-btn   color="primary" x-small   v-if="item.summary!=null && item.summary!=''" v-bind="attr"  v-on="on" >
                                    ...
                                    </v-btn>
                                </template>
                                <span>{{item.summary}}</span>
                            </v-tooltip>

                        </template>
                        <template v-slot:[`item.requirements`]="{ item }">
                             {{item.requirements==null?'':item.requirements.substr(0,15)}}
                             <v-tooltip top v-model="requirementstt" >
                                 <template v-slot:activator="{on,attr}">
                                      <v-btn   color="primary" x-small v-if="item.requirements!=null && item.requirements!=''"  v-bind="attr"  v-on="on" >
                                        ...
                                    </v-btn>
                                 </template>
                                 <span>{{item.requirements}}</span>
                             </v-tooltip>
                        </template>
                        <template v-slot:[`item.responsibilities`]="{ item }">
                            {{item.responsibilities==null?'':item.responsibilities.substr(0,15)}}
                              <v-tooltip top v-model="responsibilitiestt" >
                                <template v-slot:activator="{on,attr}">
                                     <v-btn   color="primary" x-small  v-if="item.responsibilities!=null && item.responsibilities!=''"  v-bind="attr"  v-on="on">
                                        ...
                                    </v-btn>
                                </template>
                                <span>{{item.responsibilities}}</span>
                              </v-tooltip>

                        </template>
                         <template v-slot:[`item.Action`]="{ item }">
                                    <v-icon small class="mr-2"  @click="editItem(item)" >
                                        mdi-pencil
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="grey" v-if="item.status==0" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="green" v-if="item.status==1" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveDeleteConfirm(item)" color="red"  >
                                        mdi-delete-empty
                                    </v-icon>
                        </template>
                        <template slot="no-data">
                            No current Job Titles to display
                        </template>
                    </v-data-table>
            </v-card>
        </v-container>


        <v-dialog
            v-model="Adddialog"
            max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Add Job Title</span>
                    </v-card-title>
                    <v-card-text>
                            <v-form ref="Addform" v-model="valid" lazy-validation>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-select
                                                v-model="editedItem.jobFamilyId"
                                                :items="options"
                                                :rules="[rules.required]"
                                                label="Job Family"
                                                required>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                            v-model="editedItem.englishDescription"
                                            :rules="[rules.required]"
                                            required
                                            label="English title">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                            v-model="editedItem.arabicDescription"
                                            :rules="[rules.required]"
                                            required
                                            label="Arabic title">
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                        label="Job Summary"
                                        :rules="[rules.required]"
                                        required
                                        v-model="editedItem.summary"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                    <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                        label="Job Responsibilities"
                                        :rules="[rules.required]"
                                        required
                                        v-model="editedItem.responsibilities"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                    <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                        label="Job Requirements"
                                        :rules="[rules.required]"
                                        required
                                        v-model="editedItem.requirements"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
                            <v-btn color="blue darken-1" :disabled="!valid" text @click="validateNew" >Save</v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="approveReqDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">ِAre you sure you want to Enable/Disab;e Job Title ?  </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateJobTitleStatus">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDeleteDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteJobTitle">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>



        <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    data(){
        return{
            confirmDeleteDialog:false,
            summarytt:false,
            requirementstt:false,
            responsibilitiestt:false,
            search:'',
            addJobTitleDialog:false,
            Editdialog:false,
            Adddialog:false,
            valid:false,
            editedIndex:-1,
            alertMsg:'',
            snackbar:false,
            approveReqDialog:false,
            loading:false,
            editedItem:{
                id:-1,
                jobFamily:-1,
                jobFamilyId:-1,
                englishDescription:'',
                arabicDescription:'',
                jobScope:-1,
                summary:'',
                responsibilities:'',
                requirements:'',
                status:-1

            },
            rules: {
                required: value => !!value || 'Required Fie ld',
            },
             fields:
             [
                 {value: 'id', text: 'id',width: '150' },
                 {value: 'jobFamily', text: 'job Family',width: '250' },
                 {value: 'englishDescription', text: 'English Title',width: '250' },
                 {value: 'arabicDescription', text: 'Arabic Title',width: '250'  },
                 {value: 'summary', text: 'job Summary',width: '200' },
                 {value: 'requirements', text: 'requirements',width: '200' },
                 {value: 'responsibilities', text: 'responsibilities',width: '200' },
                 {value: 'Action', text: 'Action', width:120 },
            ],
            items: [],
            options: [],
            form: {
                Id:null,
                JobFamily:null,
                EnglishDescription:null,
                ArabicDescription:null,
                JobScope:null,
                AnnualBasicSalary10thPercentile:null,
                AnnualBasicSalary1stQuartile:null,
                AnnualBasicSalaryMedian:null,
                AnnualBasicSalaryAverage:null,
                AnnualBasicSalary3rdQuartile:null,
                AnnualBasicSalary90thPercentile:null,

                TotalGuaranteedPay10thPercentile:null,
                TotalGuaranteedPay1stQuartile:null,
                TotalGuaranteedPayMedian:null,
                TotalGuaranteedPayAverage:null,
                TotalGuaranteedPay3rdQuartile:null,
                TotalGuaranteedPay90thPercentile:null,

                TotalGrossAnnual10thPercent:null,
                TotalGrossAnnual1stQuartile:null,
                TotalGrossAnnualMedian:null,
                TotalGrossAnnualAverage:null,
                TotalGrossAnnual3rdQuartile:null,
                TotalGrossAnnual90thPercentile:null,

                AnnualTotalTarget10thPercent:null,
                AnnualTotalTarget1stQuartile:null,
                AnnualTotalTargetMedian:null,
                AnnualTotalTargetAverage:null,
                AnnualTotalTarget3rdQuartile:null,
                AnnualTotalTarget90thPercentile:null,
            },
            currentEditedJobIndex:null,
            errorExists:false
        }
    },
    validations: {
        form: {
            JobFamily:{
                required
            },
            EnglishDescription: {
                required,
            },
            ArabicDescription: {
                required,
            },
            JobScope:{
                required,
            },
             AnnualBasicSalary10thPercentile: {
                required,
            },
                AnnualBasicSalary1stQuartile: {
                required,
            },
                AnnualBasicSalaryMedian: {
                required,
            },
                AnnualBasicSalaryAverage: {
                required,
            },
                AnnualBasicSalary3rdQuartile: {
                required,
            },
                AnnualBasicSalary90thPercentile: {
                required,
            },
                TotalGuaranteedPay10thPercentile: {
                required,
            },
                TotalGuaranteedPay1stQuartile: {
                required,
            },
                TotalGuaranteedPayMedian: {
                required,
            },
                TotalGuaranteedPayAverage: {
                required,
            },
                TotalGuaranteedPay3rdQuartile: {
                required,
            },
                TotalGuaranteedPay90thPercentile: {
                required,
            },
                TotalGrossAnnual10thPercent: {
                required,
            },
                TotalGrossAnnual1stQuartile: {
                required,
            },
                TotalGrossAnnualMedian: {
                required,
            },
                TotalGrossAnnualAverage: {
                required,
            },
                TotalGrossAnnual3rdQuartile: {
                required,
            },
                TotalGrossAnnual90thPercentile: {
                required,
            },
                AnnualTotalTarget10thPercent: {
                required,
            },
                AnnualTotalTarget1stQuartile: {
                required,
            },
                AnnualTotalTargetMedian: {
                required,
            },
                AnnualTotalTargetAverage: {
                required,
            },
                AnnualTotalTarget3rdQuartile: {
                required,
            },
                AnnualTotalTarget90thPercentile: {
                required,
            },
        }
    },
    methods:{
        approveDeleteConfirm(item){
            if(Boolean(item.hasMapping) == false){
                 this.editedItem = item;
                this.editedIndex = this.items.indexOf(item)
                this.confirmDeleteDialog = true
            }
            else{
                this.alertMsg = "Job cannot be deleted, it has mapped job"
                this.snackbar = true;
            }
        },
         editItem (item) {
             if(this.options == null || this.options.length == 0)
                this.getJobFamilyOptions();
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.Editdialog = true
        },
         validate () {
            if(this.$refs.form.validate())
            {
                this.updateJobTitle();
            }
         },
          updateJobTitle(){
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/JobTitle`,
                    data: {
                        id:this.editedItem.id,
                        JobFamilyId: this.editedItem.jobFamilyId,
                        EnglishDescription: this.editedItem.englishDescription,
                        ArabicDescription: this.editedItem.arabicDescription,
                        Summary: this.editedItem.summary,
                        Responsibilities: this.editedItem.responsibilities,
                        Requirements: this.editedItem.requirements,
                    }
                }).then(()=>{
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.close()
                        this.alertMsg = "Job TItle updated successfully"
                        this.snackbar = true;
                });
        },
        updateJobTitleStatus(){

            let status = -1;
            this.editedItem.status == 1 ? status = 0 : status = 1;
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/JobTitle/status?Id=${this.editedItem.id}&Status=${status}`,
                }).then(()=>{
                    this.approveReqDialog = false;
                    this.$nextTick(() => {
                                    this.items[this.editedIndex].status = status;
                                    this.alertMsg = "Job Title updated successfully"
                                    this.snackbar = true;
                             })
                });
        },
        validateNew () {
            if(this.$refs.Addform.validate())
            {
                this.AddJobTitle();
            }
         },
         showAddDialog(){
             this.clear();
             if(this.options == null || this.options.length == 0)
                this.getJobFamilyOptions();
             this.Adddialog = true;
         },
         close () {
            this.Editdialog = false
            this.Adddialog = false;
            this.$nextTick(() => {
                this.clear();
            })
        },
        clear(){
                this.editedIndex = -1
                this.editedItem.id = -1;
                this.editedItem.jobFamily=-1,
                this.editedItem.jobFamilyId=-1,
                this.editedItem.englishDescription='',
                this.editedItem.arabicDescription='',
                this.editedItem.jobScope=-1,
                this.editedItem.summary='',
                this.editedItem.responsibilities='',
                this.editedItem.requirements=''
        },
         closeApprove(){
            this.approveReqDialog = false;
            this.clear();
        },
        approveRequestConfirm(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.approveReqDialog = true;
        },
        getJobFamilyOptions(){
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/JobFamily/active`,
            })
            .then((response)=>{
                // console.log(response)
                this.options = response.data.map(val=>({
                    value:val.id,
                    text:val.englishName
                }));
            });
         },
        showAddJobTitle(){
             if(this.options.length == 0){
                this.getJobFamilyOptions();
            }
            this.onReset();
        },
        AddJobTitle(){
            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/JobTitle`,
                    headers: {'Content-Type': 'application/json' },
                    data: {
                        JobFamilyId: this.editedItem.jobFamilyId,
                        EnglishDescription: this.editedItem.englishDescription,
                        ArabicDescription: this.editedItem.arabicDescription,
                        Summary:this.editedItem.summary,
                        Responsibilities:this.editedItem.responsibilities,
                        Requirements:this.editedItem.requirements,
                    }
                }).then((response)=>{

                   this.$nextTick(() => {
                                this.items = response.data.result;
                                this.close()
                                this.alertMsg = "Job TItle added successfully"
                                this.snackbar = true;
                            });

                });
        },
        getVariant(item,activateFamily){
            if(Boolean(activateFamily) == true){
                if(item.status == 1)
                    return 'primary'
                else
                    return 'secondary'
            }
            else{
                if(item.status == 1)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        updateJobStatus(item,index,activate){
             if(activate == 1 && item.status == 1){
                this.$bvModal.msgBoxOk('Job is already Active', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                        })
                        return;
            }
            if(activate == 0 && item.status == 0){
                this.$bvModal.msgBoxOk('Job is already InActive', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                        })
                        return;
            }
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/JobTitle/status?Id=${item.id}&Status=${activate}`,
                }).then(()=>{
                        this.items[index].status = activate;
                        this.$refs.JobTitlesList.refresh();
                });
        },
        deleteJobTitle(){
            this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/JobTitle`,
                    data: {Id:this.editedItem.id}
                    }).then(()=>{
                         this.items .splice(this.editedIndex , 1);
                        this.confirmDeleteDialog = false;
                    });
        },


        onReset(){
            this.form ={
                Id:null,
                JobFamily:null,
                EnglishDescription:null,
                ArabicDescription:null,
                jobTitleScope:null,
                AnnualBasicSalary10thPercentile:null,
                AnnualBasicSalary1stQuartile:null,
                AnnualBasicSalaryMedian:null,
                AnnualBasicSalaryAverage:null,
                AnnualBasicSalary3rdQuartile:null,
                AnnualBasicSalary90thPercentile:null,
                TotalGuaranteedPay10thPercentile:null,
                TotalGuaranteedPay1stQuartile:null,
                TotalGuaranteedPayMedian:null,
                TotalGuaranteedPayAverage:null,
                TotalGuaranteedPay3rdQuartile:null,
                TotalGuaranteedPay90thPercentile:null,
                TotalGrossAnnual10thPercent:null,
                TotalGrossAnnual1stQuartile:null,
                TotalGrossAnnualMedian:null,
                TotalGrossAnnualAverage:null,
                TotalGrossAnnual3rdQuartile:null,
                TotalGrossAnnual90thPercentile:null,
                AnnualTotalTarget10thPercent:null,
                AnnualTotalTarget1stQuartile:null,
                AnnualTotalTargetMedian:null,
                AnnualTotalTargetAverage:null,
                AnnualTotalTarget3rdQuartile:null,
                AnnualTotalTarget90thPercentile:null,
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
    mounted(){
        this.loading = true;
        this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/JobTitle`,
                })
                .then((response)=>{
                    console.log(response.data)
                    this.items = response.data.result;
                    this.loading = false;
                });
    }
}
</script>

<style scoped>
    .mandatory{
        color: red
    }
</style>